@import "../styles/Variables";

details > summary::-webkit-details-marker {
  display: none;
}

.modal {
  > summary {
    list-style: none;
  }
  > summary:empty {
    display: none;
  }

  > div {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1050;
    background-color: rgba(255, 255, 255, 0.5);
    @media (prefers-color-scheme: dark) {
      background-color: rgba(0, 0, 0, 0.5);
    }
    overflow: auto;
    transition: opacity 0s ease-in-out, transform 0s ease-in-out;
    transition-duration: var(--transition-duration);
    backdrop-filter: blur(8px);
    box-sizing: border-box;
    padding: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > .modal-content {
      position: relative;
      text-align: initial;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
      border-radius: var(--border-radius);
      overflow: hidden;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      color: $color-dark;
      background-color: $color-light;
      padding:1.5rem;
      @media (prefers-color-scheme: dark) {
        color: $color-light;
        background-color: $color-dark;
      }
    }
  }

  &:not([open]) > div {
    visibility: hidden;
    transform: scale(120%);
    opacity: 0;
  }
}

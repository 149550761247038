/* variables */
@import "Variables";

/* fonts */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,500,500i,600,600i,700,700,900,900i&display=swap");

/* desktop */
:root {
  font-size: 100%;
}

body,
html {
  height: 100vh;
  background-color: $color-dark;
  margin: 0;
  padding: 0;
  text-align: left;
  scroll-behavior: smooth;
  overflow-x: hidden;
  color: $color-light;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  font-size: 1.2rem;
}

hr {
  position: relative;
  margin: 1rem 0 1rem 0;
  border: none;
  height: 0.1rem;
  background-color: $color-one;
  border-radius: $border-radius;
  width: 500%;
  left: -430%;
  &.center {
    margin: 2rem auto;
    width: 50%;
    left: unset;
  }
}

body {
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
}

a,
a:visited {
  color: #444444;
  font-weight: 700 !important;
  text-decoration: underline;
  cursor: pointer;
}
a:hover {
  color: $color-one;
  text-decoration: underline;
  img {
    opacity:.9;
    background-color: white;
  }
}

a.hashtag {
  position: relative;
  top: -7rem;
}

address {
  font-weight: 300;
  font-size: .8rem;
}

#footer a.selected {
  color: $color-one !important;
}

span.subheader,
h1.subheader,
h2.subheader,
h3.subheader {
  letter-spacing: 0.2em;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1.3rem;
  text-transform: uppercase;
  display: inline-block;
  margin-bottom: 0rem;
}

h1,
h2,
h3,
h4, .header {
  letter-spacing: -0.02em;
}

h1,
h2,
h3, div.header {
  margin: 0 0 2rem 0;
}

h1, .header {
  font-size: 4.2rem;
  line-height: 1em;
  font-weight: 700;
  &.big {
    font-size: 5rem;
    line-height: 5rem;
  }
}

h2 {
  font-size: 3.5rem;
  line-height: 4rem;
  font-weight: 700;
  flex-basis: 100%;
  strong {
    color: $color-one-light;
  }
}

h3 {
  font-size: 2.25rem;
  line-height: 2.475rem;
  font-weight: 700;
  flex-basis: 100%;
  margin: 0 0 1rem 0;
}

h4 {
  font-size: 1.5rem;
  line-height: 1.8rem;
  font-weight: 600;
  margin: 2rem 0 0.5rem 0;
  &:first-of-type {
    margin: 0 0 0.5rem 0;
  }
}

h1,
h2,
h3,
h4, .header {
  text-decoration: none;
  &.no_margin {
    margin: 0;
  }
}

p.ingress {
  font-size: 1.3rem;
  font-weight: 600 !important;
}
p.source {
  font-size: 0.8rem;
}

p {
  font-size: 1.2rem;
  margin: 0em 0 1rem 0;
  line-height: 1.8rem;
  color: $dark-p-color; 
}

.quote {
  display: block; 
  font-style: italic;
  border-left: 1px solid $color-one;
  padding-left: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  > span {
    font-weight: 700;
    color: $color-one;
  }
}

li {
  line-height: 1.8rem;
  font-size: 1rem;
}

.wrapper {
  overflow-x: hidden;
  > .slides {
    min-height: 100vh;
  }
}

button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none;
  background-color: transparent;
  padding: 0;
}

button.button,
.button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 2.5rem;
  border-radius: $border-radius;
  font-size: 0.75rem;
  padding: 0em 1rem;
  display: inline-block;
  transition: transform 0.15s ease-out;
  text-decoration: none;
  cursor: pointer;
  margin: 1rem;
  background-color: rgba(255, 255, 255, 1);
  white-space: nowrap;
  color: $color-dark;
  text-transform: uppercase;
  font-weight: 400 !important;
  letter-spacing: 0.2em;
  box-shadow: $shadow;
  text-shadow: $shadow;
  transform: translateZ(0);
  backface-visibility: hidden !important;
  perspective: 1000px;
  border-width: 0;
  &.dark {
    color: white !important;
    background-color: $color-one;
    
  }

  & + .button {
    margin-left: 1rem;
  }

  &.video {
    text-align: center;
    position: relative;
    padding-left: 3rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    &:hover {
      transform: scale(1.1);
      text-decoration: none !important;
    }

    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      line-height: 24px;
      text-align: center;
      font-size: 13px;
      left: 4px;
      top: 50%;
      width: 2rem;
      height: 2rem;
      background-color: rgba(255, 255, 255, 0.25);
      border-radius: $border-radius;
      box-shadow: $shadow;
      opacity: 0.5;
      transform: translateY(-50%);
    }
    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      left: 16px;
      top: 50%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0.4375rem 0 0.4375rem 0.6rem;
      border-color: transparent transparent transparent #ffffff;
      transform: translateY(-50%);
    }
  }

  &.selected {
    font-weight: 700;
    color: $color-light;
    background-color: #e9a047;
  }
}

.circle {
  width: 4em;
  height: 4em;
  line-height: 4em;
  background-color: $color-light;
  box-shadow: none;
  border-radius: 100%;
  margin: 0 auto 0 auto;
}

.chevroncontainer {
  font-size: 0.85rem;
  text-align: center;
  text-transform: lowercase;
  font-style: italic;
  color: $color-dark;
  text-align: center;
  position: absolute;
  top: calc(100% - 5rem);
  left: 50%;
  transform: translateX(-50%);
  .chevron {
    position: absolute;
    width: 2rem;
    height: 0.2rem;
    opacity: 1;
    transform: translateY(1rem) scale(1) translateX(-50%);
    animation: move-chevron 2s ease-in-out infinite;
    left: 50%;
    margin-top: 0.5rem;
  }
}

.logo {
  text-align: center;
  background-image: url(../include/logo/logo_white.svg);
  background-position: center left;
  background-size: contain;
  background-repeat: no-repeat;
  height: 14em;
  width: 100%;
  display: block;
  filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.15))
    drop-shadow(0 2px 4px rgba(0, 0, 0, 0.12))
    drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1))
    drop-shadow(0 8px 16px rgba(0, 0, 0, 0.07));
}

.logoblack {
  text-align: center;
  background-image: url("../include/logo/logo_black.svg");
  background-position: center left;
  background-size: contain;
  background-repeat: no-repeat;
  height: 4em;
  width: 9em;
  display: inline-block;
}

.iablogo,
.iablogo2 {
  margin: 10em 0 1em 0;
}

.iabcertificate {
  width: 100%;
}

.logo_iab,
.logo_iab2,
.logo_ro,
.logo_agency,
.logo_gasell2018,
.logo_gasell2019,
.logo_global2024,
.logo_agency2024,
.logo_agencytop30  {
  text-align: center;
  background-image: url("../include/logo/IAB_GoldStandard.png");
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 4rem;
  display: inline-block;
  width: 4rem;
}

.logo_iab2 {
  background-image: url("../include/logo/iab2.svg");
}

.logo_ro {
  background-image: url("../include/logo/RO.svg");
}

.logo_gasell2018 {
  background-image: url("../include/logo/gasell2018.svg");
}

.logo_gasell2019 {
  background-image: url("../include/logo/gasell2019.svg");
}

.logo_global2024 {
  background-image: url("../include/logo/globalfinalist.svg");
}

.logo_global2024 {
  background-image: url("../include/logo/globalfinalist.png");
}

.logo_agency {
  background-image: url("../include/logo/agencyoftheyear_bw.svg");
}

.logo_agency2024 {
  background-image: url("../include/logo/agency_2024_bw.png");
}

.logo_agencytop30 {
  background-image: url("../include/logo/agency_top30.png");
}

.logo_agency.black {
  background-image: url("../include/logo/agencyoftheyear.png");
}

.logo_agency2024.black {
  background-image: url("../include/logo/agency_2024_2.png");
}

.logo_agencytop30.black {
  background-image: url("../include/logo/agency_top30.png");
}

.logo_iab.black {
  background-image: url("../include/logo/IAB_GoldStandard_black.png");
}

.logo_iab.large {
  height: 10rem;
}

.columns {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  .column {
    margin-right: 1rem;
    margin-left: 1rem;
    flex-basis: calc(100% - 1rem);
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
  .gap {
    flex-basis: 2rem;
  }
}

ol {
  list-style: none;
  counter-reset: my-counter;
  margin: 0 auto;
  padding: 0;
  width: fit-content;
  flex-direction: column;
  display: flex;
  li {
    font-weight: 700;
    line-height: 2.5rem;
    counter-increment: my-counter;
    &::before {
      content: counter(my-counter);
      color: white;
      font-weight: 700;
      background-color: $color-one;
      border-radius: 100%;
      width: 1.75rem;
      height: 1.75rem;
      line-height: 1.75rem;
      display: inline-block;
      text-align: center;
      margin-right: 0.5rem;
    }
  }
}

.slide__group {
  position: relative;
}

.slide {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 48rem;
  overflow-y: hidden;
  clip-path: polygon(0px 2vw, 100% 0px, 100% calc(100% - 2vw), 0% 100%);
  margin-top: -2.1vw;
  transform: translateZ(0);
  backface-visibility: hidden !important;
  perspective: 1000px;
  &.collapsed {
    overflow-y: visible;
    z-index: 5;
    background: transparent !important;
    top: 50%;
    clip-path: none;
    position: absolute;
    margin-top: 0;
    height: 0;
    width: 100%;
    min-height: unset;
    &.transparent {
      .slide__content {
        .content {
          span.subheader {
            color: $color-one;
          }
          h2 {
            color: $color-dark;
          }
        }
      }
    }

    &.white,
    &.tint,
    &.dark,
    &.bright {
      background: none;
    }
    &.white {
      .slide__content {
        .content {
          background: white;
        }
      }
    }
    &.tint {
      .slide__content {
        .content {
          background: $color-tint;
        }
      }
    }
    &.dark {
      .slide__content {
        .content {
          background: $color-dark;
          
        }
      }
    }
    &.bright {
      .slide__content {
        .content {
          background: $color-light;
        }
      }
    }
    &.blue {
      .slide__content {
        .content {
          background: $color-one;
        }
      }
    }
    .slide__bg {
      display: none;
    }
    .slide__content {
      max-width: $max-width;
      position: relative;
      display: block;

      transform: translateY(-50%);
      .content {
        padding: 0;
        margin: 0;
        position: relative;

    padding-bottom: 5rem;
        &.w40 {
          flex-basis: 40%;
          width: 40%;
        }
        &.w45 {
          flex-basis: 45%;
          width: 45%;
        }
        &.w50 {
          flex-basis: 50%;
          width: 50%;
        }
        &.w55 {
          flex-basis: 55%;
          width: 55%;
        }
        &.w60 {
          flex-basis: 60%;
          width: 60%;
        }
        &.w65 {
          flex-basis: 65%;
          width: 65%;
        }
      }
    }
  }

  &:not(.collapsed) {
    &.white {
      color: $color-dark;
      background: white;
      span.subheader,
      h1.subheader,
      h2.subheader,
      h3.subheader {
        color: $color-one;
      }
      p {
        color: $default-p-color;
        &.ingress {
          color: black;
        }
      }
    }
    &.bright {
      color: $color-dark;
      background: $color-light;
      span.subheader,
      h1.subheader,
      h2.subheader,
      h3.subheader {
        color: $color-one;
      }
      p {
        color: $default-p-color;
        &.ingress {
          color: black;
        }
      }
    }
    &.dark,
    &.video {
      a { color:$color-one;}
      color: $color-light;
      background: linear-gradient(
            178.8deg,
            rgba(0, 0, 0, 0.35) calc(100vw * 0.02 + 0px),
            rgba(0, 0, 0, 0.25) calc(100vw * 0.02 + 2px),
            rgba(0, 0, 0, 0.12) calc(100vw * 0.02 + 18px),
            rgba(0, 0, 0, 0) calc(100vw * 0.02 + 4rem)
          )
          no-repeat border-box,
        linear-gradient(135deg, #282c2e 20%, #1d1f21 80%);
      &.with-illustration-right {
        background: linear-gradient(
              178.8deg,
              rgba(0, 0, 0, 0.35) calc(100vw * 0.02 + 0px),
              rgba(0, 0, 0, 0.25) calc(100vw * 0.02 + 2px),
              rgba(0, 0, 0, 0.1) calc(100vw * 0.02 + 18px),
              rgba(0, 0, 0, 0) calc(100vw * 0.02 + 4rem)
            )
            no-repeat border-box,
          radial-gradient(
            30% 60% at 70% 50%,
            rgba(223, 227, 230, 0.2) 0%,
            rgba(217, 221, 225, 0) 100%
          ),
          linear-gradient(135deg, #282c2e 20%, #1d1f21 80%);
      }
      &.with-illustration-left {
        background: linear-gradient(
              178.8deg,
              rgba(0, 0, 0, 0.35) calc(100vw * 0.02 + 0px),
              rgba(0, 0, 0, 0.25) calc(100vw * 0.02 + 2px),
              rgba(0, 0, 0, 0.1) calc(100vw * 0.02 + 18px),
              rgba(0, 0, 0, 0) calc(100vw * 0.02 + 4rem)
            )
            no-repeat border-box,
          radial-gradient(
            30% 60% at 30% 50%,
            rgba(223, 227, 230, 0.2) 0%,
            rgba(217, 221, 225, 0) 100%
          ),
          linear-gradient(135deg, #282c2e 20%, #1d1f21 80%);
      }
      &.with-illustration-center {
        background: linear-gradient(
              178.8deg,
              rgba(0, 0, 0, 0.35) calc(100vw * 0.02 + 0px),
              rgba(0, 0, 0, 0.25) calc(100vw * 0.02 + 2px),
              rgba(0, 0, 0, 0.1) calc(100vw * 0.02 + 18px),
              rgba(0, 0, 0, 0) calc(100vw * 0.02 + 4rem)
            )
            no-repeat border-box,
          radial-gradient(
            30% 60% at 50% 50%,
            rgba(223, 227, 230, 0.2) 0%,
            rgba(217, 221, 225, 0) 100%
          ),
          linear-gradient(135deg, #282c2e 20%, #1d1f21 80%);
      }
      span.subheader,
      h1.subheader,
      h2.subheader,
      h3.subheader {
        color: $color-one;
      }
      p {
        color: $tint-p-color;
        &.ingress {
          color: white;
        }
      }
    }
    &.video {
      span.subheader,
      h1.subheader,
      h2.subheader,
      h3.subheader {
        color: $color-light;
      }
      p {
        color: $color-light;
      }
    }
    &.tint {
      color: $color-light;
      background: linear-gradient(
            178.8deg,
            rgba(0, 0, 0, 0.35) calc(100vw * 0.02 + 0px),
            rgba(0, 0, 0, 0.25) calc(100vw * 0.02 + 2px),
            rgba(0, 0, 0, 0.1) calc(100vw * 0.02 + 18px),
            rgba(0, 0, 0, 0) calc(100vw * 0.02 + 4rem)
          )
          no-repeat border-box,
        linear-gradient(135deg, #485661 20%, #393c47 80%);
      &.with-illustration-right {
        background: linear-gradient(
              178.8deg,
              rgba(0, 0, 0, 0.35) calc(100vw * 0.02 + 0px),
              rgba(0, 0, 0, 0.25) calc(100vw * 0.02 + 2px),
              rgba(0, 0, 0, 0.1) calc(100vw * 0.02 + 18px),
              rgba(0, 0, 0, 0) calc(100vw * 0.02 + 4rem)
            )
            no-repeat border-box,
          radial-gradient(
            30% 60% at 70% 50%,
            rgba(223, 227, 230, 0.2) 0%,
            rgba(217, 221, 225, 0) 100%
          ),
          linear-gradient(135deg, #485661 20%, #3b464f 80%);
      }
      &.with-illustration-left {
        background: linear-gradient(
              178.8deg,
              rgba(0, 0, 0, 0.35) calc(100vw * 0.02 + 0px),
              rgba(0, 0, 0, 0.25) calc(100vw * 0.02 + 2px),
              rgba(0, 0, 0, 0.1) calc(100vw * 0.02 + 18px),
              rgba(0, 0, 0, 0) calc(100vw * 0.02 + 4rem)
            )
            no-repeat border-box,
          radial-gradient(
            30% 60% at 30% 50%,
            rgba(223, 227, 230, 0.2) 0%,
            rgba(217, 221, 225, 0) 100%
          ),
          linear-gradient(135deg, #485661 20%, #3b464f 80%);
      }
      &.with-illustration-center {
        background: linear-gradient(
              178.8deg,
              rgba(0, 0, 0, 0.35) calc(100vw * 0.02 + 0px),
              rgba(0, 0, 0, 0.25) calc(100vw * 0.02 + 2px),
              rgba(0, 0, 0, 0.1) calc(100vw * 0.02 + 18px),
              rgba(0, 0, 0, 0) calc(100vw * 0.02 + 4rem)
            )
            no-repeat border-box,
          radial-gradient(
            30% 60% at 50% 50%,
            rgba(223, 227, 230, 0.2) 0%,
            rgba(217, 221, 225, 0) 100%
          ),
          linear-gradient(135deg, #485661 20%, #3b464f 80%);
      }

      span.subheader,
      h1.subheader,
      h2.subheader,
      h3.subheader {
        color: $color-one;
      }
      p {
        color: $color-light;
        opacity: 0.6;
        &.ingress {
          color: white;
        }
      }
    }
    &.blue {
      color: $color-light;
      background: linear-gradient(
            178.8deg,
            rgba(0, 0, 0, 0.35) calc(100vw * 0.02 + 0px),
            rgba(0, 0, 0, 0.25) calc(100vw * 0.02 + 2px),
            rgba(0, 0, 0, 0.1) calc(100vw * 0.02 + 18px),
            rgba(0, 0, 0, 0) calc(100vw * 0.02 + 4rem)
          )
          no-repeat border-box,
        linear-gradient(130.97deg, #49aabb 20%, #398a98 80%);
      &.with-illustration-right {
        background: linear-gradient(
              178.8deg,
              rgba(0, 0, 0, 0.35) calc(100vw * 0.02 + 0px),
              rgba(0, 0, 0, 0.25) calc(100vw * 0.02 + 2px),
              rgba(0, 0, 0, 0.1) calc(100vw * 0.02 + 18px),
              rgba(0, 0, 0, 0) calc(100vw * 0.02 + 4rem)
            )
            no-repeat border-box,
          radial-gradient(
            30% 60% at 70% 50%,
            rgba(223, 227, 230, 0.2) 0%,
            rgba(217, 221, 225, 0) 100%
          ),
          linear-gradient(130.97deg, #49aabb 20%, #398a98 80%);
      }
      &.with-illustration-left {
        background: linear-gradient(
              178.8deg,
              rgba(0, 0, 0, 0.35) calc(100vw * 0.02 + 0px),
              rgba(0, 0, 0, 0.25) calc(100vw * 0.02 + 2px),
              rgba(0, 0, 0, 0.1) calc(100vw * 0.02 + 18px),
              rgba(0, 0, 0, 0) calc(100vw * 0.02 + 4rem)
            )
            no-repeat border-box,
          radial-gradient(
            30% 60% at 30% 50%,
            rgba(223, 227, 230, 0.2) 0%,
            rgba(217, 221, 225, 0) 100%
          ),
          linear-gradient(130.97deg, #49aabb 20%, #398a98 80%);
      }
      &.with-illustration-center {
        background: linear-gradient(
              178.8deg,
              rgba(0, 0, 0, 0.35) calc(100vw * 0.02 + 0px),
              rgba(0, 0, 0, 0.25) calc(100vw * 0.02 + 2px),
              rgba(0, 0, 0, 0.1) calc(100vw * 0.02 + 18px),
              rgba(0, 0, 0, 0) calc(100vw * 0.02 + 4rem)
            )
            no-repeat border-box,
          radial-gradient(
            30% 60% at 50% 50%,
            rgba(223, 227, 230, 0.2) 0%,
            rgba(217, 221, 225, 0) 100%
          ),
          linear-gradient(130.97deg, #49aabb 20%, #398a98 80%);
      }
      span.subheader,
      h1.subheader,
      h2.subheader,
      h3.subheader {
        color: $color-dark;
      }
      p {
        color: $color-light;
        opacity: 0.7;
        &.ingress {
          color: white;
        }
      }
    }
  }

  .slide__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: 100%;
    margin: 0 auto;
    z-index: 2;
    max-width: calc($max-width + 4rem);
    > .content {
      padding: 8rem 0;
      margin-left: 1rem;
      margin-right: 1rem;
    }
    > .content {
      align-self: center;
      text-align: left;
      flex-basis: 100%;

      &.bottom {
        align-self: flex-end !important;
      }

      &.center {
        text-align: center;
        max-width: 75%;
        margin: 0 auto;
      }
    }

    .content.mobile {
      &.bottom {
        align-self: baseline;
        margin: 0 4em 3em 4em;
      }
    }

    .content {
      &.w40 {
        flex-basis: calc(40% - 2rem);
      }
      &.w45 {
        flex-basis: calc(45% - 2rem);
      }
      &.w50 {
        flex-basis: calc(50% - 2rem);
      }
      &.w55 {
        flex-basis: calc(55% - 2rem);
      }
      &.w60 {
        flex-basis: calc(60% - 2rem);
      }
      &.w65 {
        flex-basis: calc(65% - 2rem);
      }
    }
  }
}

.slide__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
}

#pressroom {
  background-color: $color-dark;
  #pressroomafter {
    background: url(../include/logo/logo_white_outline.svg);
    background-size: 600%;
    opacity: 0.05;
    background-position-y: 25%;
  }
  .slide__content {
    margin: 1.5em 1.5em 3em 1.5em;

    .content {
      max-width: $max-width;
      margin: 0 auto;
      text-align: center;

      align-self: baseline;
    }
  }
}

#pressroom_content {
  color: black;
  .slide__content {
    .content {
      margin: 0 auto;
      flex-basis: 100%;
      max-width: $max-width;
    }
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;
  max-width: $max-width3;
  margin: 0 auto;
}

#footer {
  background: linear-gradient(
        178.8deg,
        rgba(0, 0, 0, 0.45) calc(100vw * 0.02 + 0px),
        rgba(0, 0, 0, 0.35) calc(100vw * 0.02 + 2px),
        rgba(0, 0, 0, 0.12) calc(100vw * 0.02 + 18px),
        rgba(0, 0, 0, 0) calc(100vw * 0.02 + 4rem)
      )
      no-repeat border-box,
    linear-gradient(135deg, #282c2e 20%, #1d1f21 80%), $color-dark;

  position: relative;
  color: white;
  clip-path: polygon(0px 2vw, 0px 100%, 100% 100%, 100% 0px);
  /* clip-path: polygon(
    69% 0,
    74% 6rem,
    100% 6rem,
    100% 100%,
    0 100%,
    0 6rem,
    64% 6rem
  ); */
  min-height: unset; 
  .slide__content {
    max-width: unset;
    .content {
      margin: 0;
      padding-bottom: 0;
      > .columns:first-of-type {
        justify-content: space-between;
        margin-bottom: 4rem;
        padding-left: 1rem;
        padding-right: 1rem;
        max-width: $max-width;
        margin-left: auto;
        margin-right: auto;
        > .column {
          flex-basis: content;
          text-align: right;
          margin-right: 0.5rem;
          margin-left: 0.5rem;

          &:first-of-type {
            text-align: left;
            margin-left: 0;
            flex-basis: 25%;
            p {
              font-style: italic;
              line-height: 1.4rem;
            }
          }
          &:nth-of-type(2) {
            flex-basis: 19%;
            strong {
              margin-bottom: 0.8rem;
            }
            p {
              font-size: 0.8rem;
              line-height: 1.1rem;
              display: inline-block; 
              margin-left: 0.75rem;
            }
          }
          &:nth-of-type(3) {
            flex-basis: 15%;
          }
          &:nth-of-type(4) {
            flex-basis: 13%;
          }
          &:nth-of-type(5) {
            flex-basis: 12%;
          }
          &:nth-of-type(6) {
            flex-basis: 12%;
            padding-right: 0;
            margin-right: 0;
          }
          p {
            font-size: 0.8rem;
            line-height: 1rem;
            margin: 0 0 0.5rem 0;
          }

          .header,
          strong {
            line-height: 1rem;
            margin-bottom: 1.25rem;
            margin-top: 2rem;
            display: block;
            font-weight: 600;
    font-size: 1rem;
            p {
              a {
                display: block;
              }
            }
          }

          .subpage {
            margin: 0 0 0 1rem;
            font-size: 0.6rem;
            line-height: 1.1rem;
            display: block;
          }

          strong {
            color: white;
          }

          .circle {
            width: 1.5rem;
            height: 1.5rem;
            line-height: 1.5rem;
            vertical-align: middle;
            margin: 0rem  1rem 0 0;
            display: inline-block;
            padding: 0rem;
            background-color: transparent;
            transform: none;
            position: relative;
            .icon {
              color: $color-light;
              line-height: 1rem;
            }
          }
        }
      }


      hr {
        margin: 3rem 0 3rem 0;
        width: 11.25rem;
        left: unset;
        
      }

      >  .awards {
        margin:0 auto; 
        padding-top: 3rem;
        padding-bottom: 3rem;
        background-color: rgba(255, 255, 255, 0.02);
        text-align: center;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        a, span {   width: 6rem;} 
          text-align: center; 
          .logo_iab,
          .logo_iab2,
          .logo_ro,
          .logo_agency,
          .logo_gasell2018,
          .logo_gasell2019,
          .logo_global2024 {
            height: 4rem;
            display: inline-block;
            opacity: 0.9;
            background-size: 4rem;
            background-position: center; 
          }
          .logo_ro { 
          }
          .logo_gasell2018 { 
          }
          .logo_gasell2019 {  
          }
          .logo_agency { 
          } 

        }
      > .columns.address {
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.04);
        
        margin-left: auto;
        margin-right: auto;
        flex-basis: auto; 
          a,
          a:visited  {
            height: 2.75rem;
            display: inline-block;
            line-height: 1.35rem;
            width: 2.75rem;
            margin: 0 0 0 1.5rem;
            transform: translateY(-0rem);
            border-radius: 100%;
          }
        }
      a,
      a:visited {
        font-weight: 400 !important;
        color: $default-p-color;
        display: inline-block; 
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
        strong,
        span {
          color: white;
        }
      }

      .logo {
        background-position: bottom left;
        height: 3rem;
        margin-bottom: 1.25rem;
        margin-top: 1rem;
        display: block !important;
      }

      .rewards {
        display: inline-block;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }

      .address {
        display: block;
        flex-basis: auto;
        flex-wrap: wrap;
        line-height: 1.1rem;
        font-size: 0.75rem;
        opacity: 0.85;
        font-weight: 300;
        background-color: rgba(255, 255, 255, 0.04);
        padding: 2rem 1rem;
        text-align: center;
      }
      .columns.logos {
        max-width: $max-width;
        margin: 0 auto;
        align-items: center;
        .column:last-of-type {
          text-align: right;
        }
      }
    }
  }
}


.chevron:before,
.chevron:after {
  content: "";
  position: absolute;
  top: 0.25rem;
  height: 100%;
  width: 50%;
  background: $color-light;
  &.black {
    background: $color-dark;
  }
}

.chevron.black:before,
.chevron.black:after {
  background: $color-dark;
}

.chevron.white:before,
.chevron.white:after {
  background: $color-light;
}

.chevron:before {
  left: 0.5px;
  transform: skewY(25deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skewY(-25deg);
}

.mobile {
  display: none;
}

.desktop {
  display: block;
}

@media (max-width: 820px) {
  .slide {
    min-height: 50rem;
    .slide__content {
      .content { 
        padding:5rem 0;
      }
    }
  }

  .mobile {
    display: flex;

    justify-content: center;
  }

  .desktop {
    display: none !important;
  }

  .circle {
    width: 4em;
    height: 4em;
    line-height: 4em;
  }

  h2 {
    font-size: 3rem;
    line-height: 3.45rem;
  }
   
  #footer  {
    .slide__content {
      .content { 
        padding: 8rem 0 0 0;
      }
    }
    .logo {width:auto}
    strong {
      font-size: 1rem;
    }
  }
  .icon-linkedin-black:before,
  .icon-linkedin-white:before,
  .icon-instagram-black:before,
  .icon-instagram-white:before {
    font-size: 44px !important;
  }
}

@media (max-width: 480px) {

  body,
  html {
    height: 100%;
    font-weight: 400;
  }
  p { 
    font-size: 1.1rem;

    line-height: 1.6rem;
  }

 

  h1  {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  h2, div.header {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  h3 {
    font-size: 1.75rem;
    line-height: 1.9rem;
  }

  h4 {
    font-size: 1.4rem;
    letter-spacing: normal;
    font-weight: 600;
   
  }

  li {
    line-height: 1.8rem;
    font-size: 1rem;
  }

  .columns {
    flex-wrap: wrap;
    .column {
      margin: 0 0 0 0;
      flex-basis: 100%;
    }
  }

  .chevroncontainer {
    .chevron {
      width: 1rem;
    }
  }

  button.button,
  .button {
    font-size: 0.75em;
    padding: .25rem 1.5rem;
    z-index: 999;
    letter-spacing: 0.1em;
    &.video{
      &:before{
        left:8px;
      } 
      &:after{
        left:20px;
      } 
    } 
  }



  .logo {
    height: 10em;
  }


  .slide { 
    clip-path: polygon(0 calc(5vw), 0 100%, 100% calc(100% - 5vw), 100% 0);
    margin-top: -5.1vw;
    min-height: 40rem;
    &.collapsed { 
    
      .slide__content {transform: translateY(-100%);
        .content {
          padding: 0;
          &.w40,
        &.w45,
        &.w50,
        &.w55,
        &.w60,
        &.w65 {
          flex-basis: 100% !important; 
          width: 100%;
          left:0;
        }
        }
      }
    }
    .slide__content {
      > .content {
        padding: calc(11rem + 5.1vw) 0 7rem 0;  
        margin-left: 1rem;
        margin-right: 1rem;
        &.center {
          max-width: unset;
          margin:0 1rem;
        }
        &.w40,
        &.w45,
        &.w50,
        &.w55,
        &.w60,
        &.w65 {
          flex-basis: 100% !important; 
          width: 100%;
          left:0;
        }
      } 
    }
  }

  #footer {
  
    .slide__content {
      .content {
        > .columns:first-of-type {
          margin-bottom: 0rem;
          justify-content: center;
          .column {
            flex-basis: 100%;
            text-align: center;
            font-size: 1rem;
            .header {
              margin-bottom: 0.5rem;
              padding-top: 0;
            }
            p {
              display: inline-block;
              flex-basis: 100%;
              flex-wrap: wrap;
              flex-direction: row;
              justify-content: center;
              margin-bottom: 0;
              a {
                flex-basis: content;
                line-height: 2rem;
                display: inline-block;

                padding: 0 0.5rem;
              }
            }
            br {
              height: 0;
              line-height: 0;
              flex-basis: 0;
              font-size: 0;
            }
            strong {
              margin: 2rem 0 0rem 0;
            }
            &:first-of-type {
              flex-basis: 100%;
              padding: 0 0 2rem 0;
              text-align: center;
              margin-right: 0;
              .logo {
                margin: 0 auto 3rem auto;
                background-position: center bottom;
                height: 3.5rem; 
                display: block;
              }
              p {
                text-align: center;
                margin: 0 2rem;
              }
              hr {
                margin: 1rem auto 1rem auto;
              }
              .circle {
                margin: 0 0.5rem 2rem 0.5rem;
                width: 1.35rem;
                height: 1.35rem;
                padding: 0.6rem;
              }
            }
            &:nth-of-type(2) {
              flex-basis: 100%;
            
          
            }
            &:nth-of-type(3) { 
              flex-basis: 100%;
            }
            &:nth-of-type(4) { 
              flex-basis: 100%;
            }
            &:nth-of-type(5) { 
              flex-basis: 100%;
              padding-bottom: 4rem;
            }
            &:nth-of-type(6) { 
              flex-basis: 100%;
              padding-right: 0;
              margin-right:0;
            }
      
            &:nth-child(5) {
              p {
                a {
                  display: block;
                }
              }
            }
            &:last-of-type {
              //margin-top: 5rem !important;
              strong {
                //display: none;
              }
            }
          }
        }

        >  .awards {
          max-width: 100%;
          a, span {   width: 25vw;  }  
            .logo_iab,
            .logo_iab2,
            .logo_ro,
            .logo_agency,
            .logo_gasell2018,
            .logo_gasell2019,
            .logo_global2024 {
              height: 6rem; 
              opacity: 0.9;
              background-size: 5rem; 
            }
            .logo_ro { 
            }
            .logo_gasell2018 { 
            }
            .logo_gasell2019 {  
            }
            .logo_agency { 
            } 
  
          }

        > .columns:last-of-type {

      height: 8rem;
          .column {
            flex-basis: 100%;
            
            .address {
              text-align: center;
              margin-bottom: 1rem;
            }
            &:last-of-type {
              flex-basis: 100%;
              text-align: center;
            }
            a {
              margin: 0 0 0 0.25rem;
              &:first-of-type {
                margin: 0;
              }
            }
            .address {
              color: #ebebf188;
            }
            .logo_iab,
            .logo_iab2,
            .logo_agency,
            .logo_ro,
            .logo_gasell2018,
            .logo_gasell2019 {
              background-size: 80%;
              background-position: center;
              margin: 0 0 0 0.25rem;
            }
            .logo_ro {
              margin: 0 0 0 .75rem;
            }
          }
        }
      }
    }

  }
 
}



@media (min-width: 820px) {
  button.button:hover,
  .button:hover {
    transform: scale(1.1);
    text-decoration: none !important;
  }
}

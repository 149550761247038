/* CSS icons */
[class^="icon-"]:before,
[class*=" icon-"]:before,
[class^="logo-"]:before,
[class*=" logo-"]:before {
  font-family: "Budicon Classic";
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-transform: none;
  display: inline-block;
  vertical-align: middle;
  height: 1em;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 1em;
  overflow: visible !important;
  line-height: 1em;
  opacity: 1;
  content: "";
}

.icon:before {
  display: inline-block;
  width: 1em;
}

.icon-instagram:before {
  background-image: url(../include/icons/icon_instagram4.svg) !important;
  background-size: contain;
}

.icon-linkedin:before {
  background-image: url(../include/icons/icon_linkedin.svg) !important;
  background-size: contain;
}

.icon-twitter:before {
  background-image: url(../include/icons/icon_twitter.svg) !important;
  background-size: contain;
}

.icon-youtube:before {
  background-image: url(../include/icons/icon_youtube.svg) !important;
  background-size: contain;
}

.icon-people:before {
  background-image: url(../include/icons/icon_people.svg) !important;
  background-size: contain;
}

.icon-podcast:before {
  background-image: url(../include/icons/icon_podcast.svg) !important;
  background-size: contain;
}

.icon-production:before {
  background-image: url(../include/icons/icon_production.svg) !important;
  background-size: contain;
}

.icon-events:before {
  background-image: url(../include/icons/icon_events.svg) !important;
  background-size: contain;
}

.icon-blog:before {
  background-image: url(../include/icons/icon_blog.svg) !important;
  background-size: contain;
}

.icon-tiktok:before {
  background-image: url(../include/icons/icon_tiktok.svg) !important;
  background-size: contain;
}

.icon-ninewave:before {
  background-image: url(../include/icons/icon_ninewave.svg) !important;
  background-size: contain;
}

.icon-snapchat:before {
  background-image: url(../include/icons/icon_snapchat_path.svg) !important;
  background-size: contain;
}

.icon-sweden:before,
.icon-denmark:before,
.icon-finland:before,
.icon-usa:before {
  height: 1em;
  width: 1em;
  margin-right: 0.4em;
  position: relative;
  top: -0.1em;
}

.icon-sweden:before {
  background-image: url(../include/icons/flag-sweden.svg);
}

.icon-norway:before {
  background-image: url(../include/icons/flag-norway.svg);
}

.icon-poland:before {
  background-image: url(../include/icons/flag-poland.svg);
}

.icon-spain:before {
  background-image: url(../include/icons/flag-spain.svg);
}

.icon-finland:before {
  background-image: url(../include/icons/flag-finland.svg);
}

.icon-denmark:before {
  background-image: url(../include/icons/flag-denmark.svg);
}

.icon-usa:before {
  background-image: url(../include/icons/flag-usa.svg);
}

.icon-uk:before {
  background-image: url(../include/icons/flag-uk.svg);
}

.icon-eye:before {
  background-image: url(../include/icons/eye.svg);
  content: "";
  width: 1em;
  height: 1em !important;
}

.icon-key:before {
  background-image: url(../include/icons/key.svg);
  content: "";
  width: 1em;
  height: 1em !important;
}

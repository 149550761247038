@import "../../styles/Variables";

.modal-consent {
  > div {
    bottom: 0;
    top: auto;
    justify-content: flex-end;

    > .modal-content {
      font-size: 0.7em;
      align-items: center;
      @media (min-width: 735px) {
        flex-direction: row;
      }

      > .header {
        padding: 1.5rem;

        font-size: 1.5rem;
        line-height: 1.5em;
        @media (max-width: 480px) { 
          font-size: 1rem; 
        }
        a { color: $color-one;}
      }

      > .content {
        padding: 0 1rem;
        align-self: flex-start;
        
      }

      > .bottom {
        padding: 24px;
        justify-content: flex-end;
        flex-shrink: 0;
        display: flex;
        @media (max-width: 480px) { 
          flex-direction: column-reverse;
           width: 100%;
        }

        > .button {
          margin: 0;
          display: block;
          @media (max-width: 480px) { 
            margin-bottom: 1rem; 
            text-align: center;
          }
          + .button {
            margin-left: 1rem;
            @media (max-width: 480px) { 
              
              margin-left: 0rem;
            }
          }
        }
      }
    }
  }
}

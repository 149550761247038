/* variables */
$color-dark: #232628;
$color-light: #eff4fa;
$color-tint: #424f59;
$color-tint-bright: #5d6f7e;
$default-p-color: #6b727a;
$dark-p-color: #575c64;
$tint-p-color: #989fa4;
$color-one: #42a2b2;
$color-one-dark: #368491;
$color-one-light: #7ed0de;
$color-one-white: #d4e4f1;
$color-one-shadow: #0e222530;
$color-six: #dae8f4;
$color-two: #3d494f;
$color-three: #292f32;
$color-four: #c6538c;
$color-five: #f5c25a;
$color-seven: #242425;
$color-eight: #5d6f7e;
$max-width: 980px;
$max-width2: 768px;
$max-width3: 730px;
$max-width4: 980px;
$shadow: 0 2px 5px rgba(0, 0, 0, 0.0698422), 0 4px 11px  rgba(0, 0, 0, 0.0867354),
  0 9px 22px  rgba(0, 0, 0, 0.105404);
$filtershadow: 
drop-shadow(0px 9px 28px rgba(0, 0, 0, 0.085404)) 
drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.0667354)) 
drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.0498422));
$filtershadowmobile: drop-shadow(0px 24px 51px rgba(0, 0, 0, 0.19)) 
drop-shadow(0px 9.11216px 22.344px rgba(0, 0, 0, 0.105404)) 
drop-shadow(0px 4.08179px 11.7193px rgba(0, 0, 0, 0.0867354)) 
drop-shadow(0px 1.46385px 5.13088px rgba(0, 0, 0, 0.0698422));


$easy-shadow: 0 3.2rem 1rem -1rem rgba(0, 0, 0, 0.063), 0 3.4rem 2rem -1rem rgba(0, 0, 0, 0.047),
0 4rem 4rem -1rem rgba(0, 0, 0, 0.035), 0 5rem 8rem -1rem rgba(0, 0, 0, 0.023) ;
$text-shadow:  0 2px 5px rgba(0, 0, 0, 0.0698422), 0 4px 11px  rgba(0, 0, 0, 0.0867354),
0 9px 22px  rgba(0, 0, 0, 0.105404);
$shadow_phone: 0 2.8px 2.2px 1px rgba(0, 0, 0, 0.037),
  0 6.7px 5.3px 2px rgba(0, 0, 0, 0.053), 0 12.5px 10px 3px rgba(0, 0, 0, 0.065),
  0 22.3px 17.9px 5px rgba(0, 0, 0, 0.077),
  0 41.8px 33.4px 10px rgba(0, 0, 0, 0.096),
  0 100px 80px 20px rgba(0, 0, 0, 0.13);
$border-radius: .75rem;

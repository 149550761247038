/* variables */
@import "Variables";

@media (max-width: 820px) {
  

 



  .scrollbar-track {
    display: none;
  }
  #career_content {
    .slide__content {
      padding: 2em;
      margin: 0;
    }
  }



  

 

  



 

  .case_type1,
  .case_type2,
  .case_type3,
  .case_type4,
  .case_type5 {
    .slide__content {
      .content {
        span.desktop {
          display: block !important;
        }
      }
    }
  }

  .case_type1_image,
  .case_type2_image,
  .case_type3_image,
  .case_type4_image,
  .case_type5_image {
    div.lax {
      &.mobile {
        display: none !important;
      }
    }
  }

  

 
 
 

  #casestudies .slide__content .content .cases {
    position: relative;
        width: calc(100% + 2rem);
        left: -1rem; 
        .case {
          flex-basis: calc(50% - 2rem);
          margin: 1rem 1rem 2rem 1rem;
        }
  }

  #case_gainomax_image {
    .slide__content {
      .content {
        text-align: center;
        flex-basis: 100%;
        
        h1 {
          text-align: center;
          font-size: 2.5em !important;
          text-shadow: none;
        }
      }
    }
  }

  #case_gainomax_robinmos,
  #case_gainomax_jonasfagerstrom,
  #case_gainomax_ellenbergstrom,
  #case_gainomax_moamattsson {
    .content {
      max-width: 100%;
    }
  }

  #case_gainomax_3 {
    .content {
      .columns {
        .column {
          flex-basis: 70%;
          margin-left: auto;
          margin-right: auto;
          padding-bottom: 2em;
        }
      }
    }
  }




}

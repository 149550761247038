/* variables */
@import "Variables";

@media (min-width: 820px) {
 .gradient-blur {
  height: 7rem; 
  position: fixed; 
  inset:0 0 auto 0;
  z-index: 5; 
  > div.blur,
  &::before,
  &::after {
    position: absolute;
    inset: 0;
  } 
  &::after {
    content: "";
    z-index: 8;
    backdrop-filter: blur(20px);
    mask: linear-gradient(
      to top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 90%
    );
  }
} }


.menuwrapper {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.6) 0%, 
    rgba(0, 0, 0, 0) 100%
  ); 
  height: 7rem; 
  position: fixed; 
  inset:0 0 auto 0;
  z-index: 95; 
   

  

  .menu {
    width:100%;
    max-width: calc($max-width + 4rem); 
    margin: 0 auto;
    z-index: 2;
    .logoblack,
    .logo {
      height: 2.5rem;
      width: 5rem;
      margin-left: 0rem;
      //flex-basis: content; 
      border-radius: 0 !important;
    }

    #menuHome,
    #menuIcon {
      display: inline-block;
    }

    > .columns {
      justify-content: space-between;
    align-items: center;
      display: flex; 
      margin:0 1rem;
      padding-top:1.5rem;
      .column { 
        align-self: center; 
        margin:0; 
        flex-basis: 35%;
       
        button {
          &:focus {
            + .knowledge_submenu {
              display:block;
            }
          }
           + .knowledge_submenu {
            display:none;
          }
        }
        
        .columns {
          justify-content: space-between;
    align-items: center;
          .column{ margin-left:.5rem;
            flex: none;
          }
        }
      }
      a,
      .a {
        border-radius:.5rem; 
        letter-spacing: 0.02em;
        white-space: nowrap;
        text-align: center;
        line-height: 2rem;
        display: block;
        flex-basis: auto;
        color: #dbdbe2;
        text-decoration: none;
        font-weight: 400 !important;
        position: relative;
        text-shadow: $text-shadow; 
        font-size: 1rem;
        padding: 0 .75rem;
        margin-right: 0;
        margin-left: 0;
        user-select: none;
 opacity:.95;

          #burgercheckbox { 
            display: none;
           

            + label {
              display: block;
              width: 1rem;
         
               + .submenuburger {
                width:8rem;
                white-space: normal;
                padding:.25rem .75rem; 
      
                p { font-size: .8rem; line-height: 1rem; color:$color-light;   font-weight: normal; letter-spacing: normal;}
               }

               
            } 
            &:not(:checked) {
              + label {
                + .submenuburger, + .submenu {
                  opacity: 0;
                  display: none;
                }
              }
            }

            &:checked {
              + label {
                + .submenuburger {
                  opacity: 1; 
                }
              }
            }

          }

           #languagecheckbox{ 
            display: none;
  
            + label {
              display: block;
              width: 100%;
            
               

               .langvalue {
                color: $color-one;
               }

            } 
            &:not(:checked) {
              + label {
                + .submenuburger, + .submenu {
                  opacity: 0;
                  display: none;
                }
              }
            }

            &:checked {
              + label {
                + .submenuburger {
                  opacity: 1; 
                }
              }
            }

          }

          #knowledgecheckbox{
            display: none;
         
            
            &:not(:checked) {
              + label {
                padding-right: 1rem;
                display: block;
                height: 100%;
                
                &:after {
                  content: "";
                  position: absolute;
                  right: .9rem;
                  top: 50%;
                  transform: translateY(-50%);width: 0;
                  height: 0;
                  border-top: 0.3rem solid transparent;
                  border-bottom: 0.3rem solid transparent;

                  border-left: 0.3rem solid white;
                }
                + .submenu, + .knowledge_submenu {
                  opacity: 0;
                  display: none;
                }
              }
            }
            &:checked {
              + label {
                color: $color-one;
                padding-right: 1rem;
                display: block;
                height: 100%;
                .langvalue { 
                  opacity: 0; 
                }
                &:after {
                  content: "";
                  position: absolute;
                  right:  .8rem;;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 0;
                  height: 0;
                  border-left: 0.3rem solid transparent;
                  border-right: 0.3rem solid transparent;

                  border-top: 0.4rem solid $color-one;
                }
                + .submenu, + .knowledge_submenu {
                  opacity: 1;
                }
              }
            }
          } 
        .iconArrowRight {
          display: none;
        }

        &.mobile {
          display: none;
        }

        div.icon {
          display: inline-block;
          border-radius: 2em;
          margin-left: 0.5rem;
        }

       

         .knowledge_submenu, .submenuburger {
          position: absolute;
          left: 50%;
          top: 2.5rem;
          display: block;
          font-size: 0.8rem;
          opacity: 0;
          overflow: visible;
          transition: opacity 0.3s ease-out;
          border: none;
          //z-index: -1;
          width: 100%;
          background-color: $color-dark;
          padding: .25rem 0.75rem;
          border-radius: 1rem;
          transform: translateX(calc(-50%));
          filter: $filtershadow;
          a,
          .a {
            text-shadow: none;
            display: block;
            line-height: 1.1rem;
            margin: 0.5rem 0;
            padding: .5rem .75rem;
            color: $color-light;
            text-align: center; 
            font-size: .7rem;
            white-space: normal;
            &:hover {
              background-color: rgba(255, 255, 255, 0.05);
              color: #eee;
            }
            &.selected {
              color: #fff;
              background-color: $color-one;
            }
          }
        }

        .knowledge_submenu {
          width:10rem;
          white-space: normal;
          padding:.25rem .75rem; 

          p { font-size: .8rem; line-height: 1rem; color:$color-light;   font-weight: normal; letter-spacing: normal;}
         }

        &.selected {
          color: #fff;
          background-color: $color-one;
          filter: $filtershadow;

          &:focus {
            color: $color-light;

            > .submenu, > .knowledge_submenu {
              opacity: 1;
            }
          }
        }
        &:focus {
          color: $color-one;

          > .submenu, > .knowledge_submenu {
            opacity: 1;
          }
        }
      }
    }
  }
  .overlay {
    background-color: rgba(0, 255, 255, 0.1);
    color: rgba(0, 255, 255, 0.1);
    mix-blend-mode: multiply;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
}

@media (max-width: 820px) {
  .menuwrapper {
    .menu {
      max-width: $max-width;
      > div {
        margin-right: 0em;
      }
    }
  }
 
}

@media (max-width: 480px) {
  .menuwrapper {
  .menu {
    transform: none !important;
    left:0;
    width: 100%;
    .logo {
      margin-left:1rem; 
      height: 2.75rem;
    }
    > .columns { 
      margin: 0 1rem;
      > .column {
      flex-basis: auto;
        .columns {
          .column {
            margin: 0 .5rem;
            text-align: left;
            padding: .5rem 1rem;
            font-size: 1rem;
            flex-basis: calc(100% - 3rem);
            &.last {
              padding:0;
            }
          }
        }
      }
      .a {
        #burgercheckbox {
          +label {
            display: none !important;
          }
        }
        .submenuburger {
          display: block !important;
          opacity: 1 !important;
          position: relative;
          top: 0;
          left: 0;
          transform: none;
          background-color: transparent; 
          border-radius: 0;
          padding: 0 !important;
          width: 100% !important;
          a, .a {
            text-align: left;  
            font-size: 1rem;
            padding: .5rem 1rem;
            line-height: 2rem;
            margin-top:0;
            margin-bottom: 0;
            #languagecheckbox:not(:checked),#languagecheckbox:checked {
            +label {
              display: none;
              +.submenu {
                display: block !important;
                opacity: 1 !important;
                width: 100%;
                padding: 0;
                left:0;
                a {
                  font-size: .8rem;
                  display: inline-block;
                  width: calc(50% - 1rem);
                  border-radius: 0;
                  text-align: center;
                  margin:0;
                }
                
              }
            }
             
          }
          }
          > .a { margin-bottom: .5rem; 
            
            padding: 0; 
            padding-top:.75rem; 
            border-top:0.5px solid $color-tint;
            border-radius: 0;
            .submenu {
              border-radius: 2rem;
              overflow:hidden; 
              background-color:$color-dark; 
              border:0.5px solid $color-one-light;
            }
            margin-left:.5rem}
        }
        
         #knowledgecheckbox {
          &:not(:checked), &:checked {
            + label {
              .langlabel {
                width: 50%;
                display: inline-block;
                
              }
              .langvalue {
                width: 50%;
                color:$color-one;
                display: inline-block;
                text-align: right;
              }
              &:after {
                top:1.5rem;
              }
            }
             
        } 
      }
    }
     
    }
    #menuHome {
      display: block;
    }
    #menuIcon {
      display: block !important;
      width: 1.5rem;
      height: 1.5rem;
      line-height: 1.5rem;
      padding: 0.625rem;
    }

    .mainmenu {
      box-shadow: $shadow;
      display: block;
      background-color: #282828dd;
      top: 4.25rem;
      position: fixed;
      backdrop-filter: blur(15px);
    width: 15rem;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius; 
      border-top-left-radius: $border-radius;
      padding-top:.5rem;
      padding-bottom:.5rem; 
      right: .75rem; 
      z-index: -1;
      &::before  {
        content:"";
  
        width: 3.25rem;
        height: 3.25rem;
        position: absolute;
        top: -3.25rem;
        right: -0rem;
        background-color: #282828dd;
        border-top-right-radius: $border-radius; 
        border-top-left-radius: $border-radius;
        backdrop-filter: blur(15px);
      }
      &.hide {
        display: none;
      }

      .a.column {
        span,
        .icon {
          display: none;
        }
        .submenu,.knowledge_submenu {
          display: flex; 
          flex-wrap: wrap;
          opacity: 1;
          top: 0;
          box-shadow: none;
          background: transparent;
          position: relative;
          font-size: 1rem;
          padding:0; 
          left: -.5rem;
          transform: none; 
          width: calc(100% - 2.5rem);
          a {
            flex-basis: 100%;
            text-align: left; 
            margin: 0 0 0 .5rem;
            font-size: .9rem;
            line-height: 1.2rem;
            padding: .5rem .5rem;
           
          }
        }

        .knowledge_submenu {
          width: 100%;
        }
      }

      > a,
      .a {
        text-align: left;
      
        &.selected {
          background-color: $color-one;
        }

        > div.iconArrowRight {
          display: block;
          width: 16px;
          height: 16px;
          position: absolute;
          right: calc(1.5em - 4px);
          top: 50%;
          transform: translateY(-50%) rotateZ(180deg) !important;
          margin-left: 0 !important;
          line-height: 20px;
        }
        &.mobile {
          overflow: hidden;
          padding: 0;
          display: block;
          > div {
            border: 2px solid rgba(255, 255, 255, 0.2);
            width: 15%;
            height: 0;
            margin: 0.5em auto;
            border-radius: 2px;
          }
        }
      }
      > .a {
        padding: 0;
      }
    }
  }
}} 

@keyframes fadein_one {
  0% {
    opacity: 1;
  } 
  22% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  97% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein_two {
  0% {
    opacity: 0;
  }

  22% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  47% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadein_three {
  0% {
    opacity: 0;
  }

  47% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  72% {
    opacity: 1;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadein_four {
  0% {
    opacity: 0;
  }

  72% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  97% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadein_3parts_one {
  0% {
    opacity: 1;
  } 
  30% {
    opacity: 1;
  }

  33% {
    opacity: 0;
  }

  97% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein_3parts_two {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  33% {
    opacity: 1;
  }

  63% {
    opacity: 1;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadein_3parts_three {
  0% {
    opacity: 0;
  }

  63% {
    opacity: 0;
  }

  66% {
    opacity: 1;
  }

  97% {
    opacity: 1;
  }
 
  100% {
    opacity: 0;
  }
}

@keyframes move-chevron {
  50% {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }

  100% {
    opacity: 1;
    transform: translateY(1rem) translateX(-50%);
  }
}

@keyframes evolvingafter {
  0% {
    transform: translateX(-20vw);
  }

  100% {
    transform: translateX(20vw);
  }
}

@keyframes glidebg {
  0% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(25%);
  }
}

[class^="logo-"],
[class*=" logo-"] {
  width: 100%;
  margin: 0 auto 0 auto;
}

[class^="logo-"]:before,
[class*=" logo-"]:before {
  width: 100%;
  height: 2em !important;
  margin: 0 auto 0 auto;
  content: "";
  background-size: contain;
  color: inherit;
  background-repeat: no-repeat;
}




